import './style.css'

import * as THREE from 'three'

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Pokeball
 */

const pokeball = new THREE.Group()

const topHalf = new THREE.Mesh(
    new THREE.SphereGeometry(1, 32, 32, 0, 2*Math.PI, 0, Math.PI/2),
    new THREE.MeshStandardMaterial({ color: '#ff0000' })
)

const bottomHalf = new THREE.Mesh(
    new THREE.SphereGeometry(1, 32, 32, 0, 2*Math.PI, 0, Math.PI/2),
    new THREE.MeshStandardMaterial({ color: '#ffffff' })
)

const button = new THREE.Mesh(
    new THREE.CylinderGeometry(0.1, 0.1, 0.05, 32, 32),
    new THREE.MeshStandardMaterial({color: '#ffffff'})
)

const buttonBack = new THREE.Mesh(
    new THREE.CylinderGeometry(0.15, 0.15, 0.2, 32, 32),
    new THREE.MeshStandardMaterial({color: '#000000'})
)

//TODO center

bottomHalf.rotation.x = Math.PI;
topHalf.position.y = 1.1
bottomHalf.position.y = 1

button.rotation.z = Math.PI*1.5
button.position.x = 1
button.position.y = 1.05

buttonBack.rotation.z = Math.PI*1.5
buttonBack.position.x = 0.9
buttonBack.position.y = 1.05

pokeball.add(topHalf)
pokeball.add(bottomHalf)
pokeball.add(button)
pokeball.add(buttonBack)

scene.add(pokeball)

// Floor
const floor = new THREE.Mesh(
    new THREE.PlaneGeometry(20, 20),
    new THREE.MeshStandardMaterial({ color: '#0000ff' })
)
floor.rotation.x = - Math.PI * 0.5
floor.position.y = 0
// scene.add(floor)

/**
 * Lights
 */
// Ambient light
const ambientLight = new THREE.AmbientLight('#ffffff', 0.5)
scene.add(ambientLight)

// Directional light
const directionalLight = new THREE.DirectionalLight('#ffffff', 0.5)
directionalLight.position.set(4, 5, - 2)
scene.add(directionalLight)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 4
camera.position.y = 3
camera.position.z = 4
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */

const tick = () =>
{
    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()